import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { t } from "i18next";
import { Col, DatePicker, Collapse, Row, Tag } from "antd";
import { usePost } from "../../../../hooks/usePost";
import CardContainer from "../../../../components/Containers/CardContainer";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { getBlockedUsersDaily } from "../../../../services/admin_s";
import {
    carrierNames,
    getParcelImageForDashboard,
} from "../../../../services/records";
import { FilterRequest } from "../../../../services/user_d";
import { isNegativeNumber } from "../../../DashboardSections/UsersBeta/utils";
import { useMediaQuery } from "react-responsive";
import { UserBlacklistStatus } from "../../../../components/Common/UserBlacklistStatus";
import UserAvatar from "../../../DashboardSections/UsersBeta/Components/UserAvatar";
const { Panel } = Collapse;

type Props = {
    country: string;
};

interface Styles {
    collapsedItem: React.CSSProperties;
    collapsedValue: React.CSSProperties;
}

const styles: Styles = {
    collapsedItem: {
        paddingLeft: 25,
        overflowWrap: "break-word",
        whiteSpace: "normal",
        maxWidth: "100%",
    },
    collapsedValue: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #ccc",
    }
}

export const BlockedUsersDaily = ({ country }: Props) => {
    const isMobile = useMediaQuery({ query: '(max-width: 460px)' })
    const isTablet = useMediaQuery({ query: '(max-width: 768px)' })
    const [ranges, setRanges] = useState<moment.Moment[] | null[]>([null, null]);
    const {
        fetchData,
        data: filteredData,
        isLoading,
    } = usePost<FilterRequest, any>(getBlockedUsersDaily);

    const getData = useCallback(async () => {
        await fetchData({
            "origin.country": country,
            created_at: {
                $gte: ranges[0] === null ? '' : ranges[0].startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                $lte: ranges[1] === null ? '' : ranges[1].endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            },
        });
    }, [fetchData, country, ranges]);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ranges, country]);

    return (
        <CardContainer
            cardStyle={{
                marginTop: "20px",
                overflow: "hidden",
                padding: "20px",
                borderBottom: "1px solid #ccc",
            }}
            titleStyle={{ placeItems: "center" }}
            title="Usuarios Bloqueados recientemente"
        >
            <DatePicker.RangePicker
                onChange={(e: any) => {
                    setRanges([moment(e[0]).startOf("day"), moment(e[1]).endOf("day")]);
                }}
                placeholder={["Fecha inicial", "Fecha final"]}
                value={ranges as any}
                style={{ width: "100%" }}
            />
            <hr />
            <Row>
                <Col
                    xs={18}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={14}
                    style={{ fontWeight: "bold", textAlign: "start", left: '32px' }}>
                    <p>Usuario</p>
                </Col>
                {
                    !isTablet && (
                        <Col
                            xs={4}
                            md={4}
                            lg={4}
                            xl={4}
                            xxl={4}
                            style={{ fontWeight: "bold", textAlign: "end", right: '36px' }}>
                            <p>Tipo</p>
                        </Col>
                    )
                }

                {
                    !isMobile && (
                        <Col
                            xs={6}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={6}
                            style={{ fontWeight: "bold", textAlign: "end", right: '36px' }}>
                            <p>Fecha</p>
                        </Col>
                    )
                }

            </Row>
            <Row
                align="middle"
                style={{
                    maxHeight: "36vh",
                    paddingRight: "20px",
                    overflowY: "scroll",
                }}
            >
                {isLoading ? (
                    <LoadingAnimation animationType="small" />
                ) : filteredData === undefined ||
                    filteredData?.length === 0 ? (
                    <p
                        style={{
                            fontWeight: "bold",
                            margin: "50px auto",
                            color: "#0008",
                        }}
                    >
                        No hay datos para mostrar.
                    </p>
                ) : (
                    <Collapse
                        bordered={false}
                        style={{ background: "transparent", width: "100%" }}
                    >
                        {filteredData?.map((item: any) => {
                            return (
                                <Panel
                                    key={item.email}
                                    header={
                                        <Row
                                            justify="space-between"
                                            style={{ alignItems: "center", width: "100%" }}
                                        >
                                            <Col
                                                xs={18}
                                                md={14}
                                                lg={14}
                                                xl={14}
                                                xxl={14}
                                                style={{ display: "flex", alignItems: "center" }}
                                            >
                                                <span
                                                    style={{ marginLeft: "10px", whiteSpace: "nowrap", display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                                >
                                                    {item.email}
                                                </span>
                                            </Col>
                                            {
                                                !isTablet && (
                                                    <Col
                                                        xs={0}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "right",
                                                            fontWeight: "bold",
                                                            color: "#2D1B6E",
                                                        }}
                                                    >
                                                        <UserBlacklistStatus status={item.type} />
                                                    </Col>
                                                )
                                            }
                                            {
                                                !isMobile && (
                                                    <Col
                                                        xs={6}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}
                                                        xxl={6}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "right",
                                                            fontWeight: "bold",
                                                            color: "#2D1B6E",
                                                        }}
                                                    >
                                                        {moment(item.ban_date).format('YYYY/MM/DD')}
                                                    </Col>
                                                )
                                            }

                                        </Row>
                                    }
                                    style={{ background: "transparent", border: "none" }}
                                >
                                    <Row
                                        key={item.carrier}
                                        justify="space-between"
                                        style={{
                                            margin: "5px 0",
                                            flexWrap: "wrap",
                                            width: "100%",
                                        }}
                                    >
                                        <Col
                                            span={24}
                                            style={styles.collapsedItem}
                                        >
                                            <p style={{ margin: 0, fontWeight: "bolder", textAlign: "start", marginTop: '12px' }}>
                                                Motivo del bloqueo
                                            </p>
                                        </Col>
                                        <Col
                                            span={24}
                                            style={styles.collapsedItem}
                                        >
                                            <div
                                                style={styles.collapsedValue}
                                            >
                                                <div>
                                                    {item.block_reason}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col
                                            span={24}
                                            style={styles.collapsedItem}
                                        >
                                            <p style={{ margin: 0, fontWeight: "bolder", textAlign: "start", marginTop: '12px' }}>
                                                Dirección IP
                                            </p>
                                        </Col>
                                        <Col
                                            span={24}
                                            style={styles.collapsedItem}
                                        >
                                            <div
                                                style={styles.collapsedValue}
                                            >
                                                <div>
                                                    {item.metadata.last_ip}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col
                                            span={24}
                                            style={styles.collapsedItem}
                                        >
                                            <p style={{ margin: 0, fontWeight: "bolder", textAlign: "start", marginTop: '12px' }}>
                                                Usuario
                                            </p>
                                        </Col>
                                        <Col
                                            span={24}
                                            style={styles.collapsedItem}
                                        >
                                            <div
                                                style={styles.collapsedValue}
                                            >
                                                <div>
                                                    Correo: {item.email}{' '}
                                                    {
                                                        item?.userData?.security?.isBlocked && (
                                                            <Tag style={{ marginBottom: '4px' }} color={"red"}>Bloqueado</Tag>
                                                        )
                                                    }

                                                </div>
                                            </div>

                                        </Col>
                                        <Col
                                            span={24}
                                            style={styles.collapsedItem}
                                        >
                                            <div
                                                style={styles.collapsedValue}
                                            >
                                                <div>
                                                    Nombre: {item.profile.name}{' '}

                                                </div>
                                            </div>

                                        </Col>
                                        <Col
                                            span={24}
                                            style={styles.collapsedItem}
                                        >
                                            <div
                                                style={styles.collapsedValue}
                                            >
                                                <div>
                                                    UserId: {item.user}{' '}

                                                </div>
                                            </div>

                                        </Col>
                                        <Col
                                            span={24}
                                            style={styles.collapsedItem}
                                        >
                                            <div
                                                style={styles.collapsedValue}
                                            >
                                                <div>
                                                    Saldo:
                                                    <span style={isNegativeNumber(item.userData.balance) ? { color: 'red' } : {}}>
                                                        {' '}${item.userData.balance}</span>
                                                </div>
                                            </div>

                                        </Col>
                                        <Col
                                            span={24}
                                            style={styles.collapsedItem}
                                        >
                                            <div
                                                style={styles.collapsedValue}
                                            >
                                                <div>
                                                    Registro: {moment(item.userData.created_at).format('YYYY/MM/DD')}{' '}
                                                </div>
                                            </div>

                                        </Col>
                                        <Col
                                            span={24}
                                            style={styles.collapsedItem}
                                        >
                                            <p style={{ margin: 0, fontWeight: "bolder", textAlign: "start", marginTop: '12px' }}>
                                                Fecha del bloqueo
                                            </p>
                                        </Col>
                                        <Col
                                            span={24}
                                            style={styles.collapsedItem}
                                        >
                                            <div
                                                style={styles.collapsedValue}
                                            >
                                                <div>
                                                    {new Date(item.ban_date).toLocaleString()}
                                                </div>
                                            </div>
                                        </Col>
                                        {
                                            isTablet && (
                                                <>
                                                    <Col
                                                        span={24}
                                                        style={styles.collapsedItem}
                                                    >
                                                        <p style={{ margin: 0, fontWeight: "bolder", textAlign: "start", marginTop: '12px' }}>
                                                            Precio del envío
                                                        </p>
                                                    </Col>
                                                    <Col
                                                        span={24}
                                                        style={styles.collapsedItem}
                                                    >
                                                        <div
                                                            style={styles.collapsedValue}
                                                        >
                                                            <div>
                                                                ${item.email}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </>
                                            )
                                        }

                                    </Row>
                                </Panel>
                            );
                        })}
                    </Collapse>
                )}
            </Row>
        </CardContainer>
    );
};
